import axios from "axios";
import { useContext } from "react";
import {
  BackofficeContext,
  BakcofficeContext,
  useBackofficeContext,
} from "./BackofficeContext";
const getEndpointInfo = (target) => {
  const locToken = sessionStorage.getItem("locToken");
  const uatToken = sessionStorage.getItem("uatToken");
  const prdToken = sessionStorage.getItem("prdToken");

  let headers = {
    Authorization: `Token ${prdToken}`,
  };
  let endpoint = "";
  switch (target.toUpperCase()) {
    case "PRD":
      headers = {
        Authorization: `Token ${prdToken}`,
      };
      endpoint = process.env.REACT_APP_API_ENDPOINT;
      break;
    case "UAT":
      headers = {
        Authorization: `Token ${uatToken}`,
      };
      endpoint = process.env.REACT_APP_UATAPI_ENDPOINT;
      break;
    case "LOC":
      headers = {
        Authorization: `Token ${locToken}`,
      };
      endpoint = process.env.REACT_APP_LOCAPI_ENDPOINT;
      break;
    default:
      headers = {
        Authorization: `Token ${prdToken}`,
      };
      endpoint = process.env.REACT_APP_API_ENDPOINT;
      break;
  }

  return { headers, endpoint };
};

export const useGetSocketEndpoint = () => {
  const { environment } = useContext(BackofficeContext);
  switch (environment) {
    case "PRD":
      return process.env.REACT_APP_WS_ENDPOINT;
      break;
    case "UAT":
      return process.env.REACT_APP_DEVWS_ENDPOINT;
      break;
    case "LOC":
      return process.env.REACT_APP_LOCWS_ENDPOINT;
      break;
    default:
      return process.env.REACT_APP_WS_ENDPOINT;
      break;
  }
};

export async function GetApi(target, uri, data, signal = false) {
  const { headers, endpoint } = getEndpointInfo(target);
  if (signal) {
    return axios
      .get(`${endpoint}${uri}`, {
        headers: headers,
        signal: signal,
      })
      .then((response) => response)
      .catch((error) => {
        if (error.code == "ERR_CANCELED") {
          throw error;
        } else {
          console.log(error);
        }
      });
  } else {
    return axios
      .get(`${endpoint}${uri}`, { headers: headers })
      .then((response) => response)
      .catch((error) => console.log(error));
  }
}

export const useGetApiFromContext = () => {
  const { environment } = useContext(BackofficeContext);
  const { headers, endpoint } = getEndpointInfo(environment ||"DEV");
  const getApiFromContext = async (uri, data, signal = false) => {
    if (signal) {
      return axios
        .get(`${endpoint}${uri}`, {
          headers: headers,
          signal: signal,
        })
        .then((response) => response)
        .catch((error) => {
          if (error.code == "ERR_CANCELED") {
            throw error;
          } else {
            console.log(error);
          }
        });
    } else {
      return axios
        .get(`${endpoint}${uri}`, { headers: headers })
        .then((response) => response)
        .catch((error) => console.log(error));
    }
  };
  return getApiFromContext;
};

export async function PostApi(target, uri, data) {
  const { headers, endpoint } = getEndpointInfo(target);
  return axios
    .post(`${endpoint}${uri}`, data, { headers: headers })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function PatchApi(target, uri, data) {
  const { headers, endpoint } = getEndpointInfo(target);
  return axios
    .patch(`${endpoint}${uri}`, data, { headers: headers })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export function useGetApiAsTenant() {
  const { tenantId, environment } = useContext(BackofficeContext);
  const { headers, endpoint } = getEndpointInfo(environment);
  headers.tenantid = tenantId;
  const getApiAsTenant = async (uri, data, signal = false) => {
    if (signal) {
      return axios
        .get(`${endpoint}${uri}`, {
          headers: headers,
          signal: signal,
        })
        .then((response) => response)
        .catch((error) => {
          if (error.code == "ERR_CANCELED") {
            throw error;
          } else {
            console.log(error);
          }
        });
    } else {
      return axios
        .get(`${endpoint}${uri}`, { headers: headers })
        .then((response) => response)
        .catch((error) => console.log(error));
    }
  };
  return getApiAsTenant;
}

export function usePostApiAsTenant(tenant){
  const { tenantId,environment } = useContext(BackofficeContext);
  const { headers, endpoint } = getEndpointInfo(environment);
  headers.tenantId = tenant ? tenant : tenantId;
  return async (uri, data) => axios
    .post(`${endpoint}${uri}`, data, { headers: headers })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function PatchApiAsTenant(target, uri, data) {
  const { headers, endpoint } = getEndpointInfo(target);
  const { tenantId } = useContext(BackofficeContext);
  headers.tenantId = tenantId;
  return axios
    .patch(`${endpoint}${uri}`, data, { headers: headers })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export function usePostApiFromContext(){
  const { environment } = useContext(BackofficeContext);
  const { headers, endpoint } = getEndpointInfo(environment);
  return async (uri, data) => axios
    .post(`${endpoint}${uri}`, data, { headers: headers })
    .then((response) => response)
    .catch((error) => console.log(error));
}