import React, { useState, useEffect } from "react";
import { GetApi } from "../../tools/axiosFunctions";
import { LoadingTable } from "../../components/system/layout/LoadingElements";
import { PageTitle } from "../../components/system/layout/PageElements";
import AppleTokensTable from "../../components/customers/AppleTokensTable";
import { useContext } from "react";
import { BackofficeContext } from "../../tools/BackofficeContext";

function AppleTokens() {
  const [customerData, setCustomerData] = useState(null);
  const { environment, refresh } = useContext(BackofficeContext);
  useEffect(() => {
    GetApi(environment, "/auth/backoffice/tenant/").then((response) => {
      let info = response.data;
      let tenants = [];
      info.map((tenant) => {
        if (tenant.management_info?.ipadOnboarded ||tenant.management_info?.macosOnboarded) {
          tenants.push(tenant);
        }
      });
      setCustomerData(tenants);
    });
  }, [refresh]);
  return (
    <>
      <PageTitle>Apple Token Management</PageTitle>
      {customerData ? (
        <AppleTokensTable data={customerData} />
      ) : (
        <LoadingTable />
      )}
    </>
  );
}

export default AppleTokens;
