import React, { useState, useCallback, createContext, useContext } from 'react';
import { Snackbar, Alert, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs

// Create a context for notifications
const NotificationContext = createContext();

// Hook to use the notification context
export const useNotification = () => useContext(NotificationContext);

// Notification Provider component
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Function to send a notification
  const sendNotification = useCallback((message, severity = 'info') => {
    const id = uuidv4(); // Generate a unique ID for each notification
    setNotifications((prev) => [...prev, { id, message, severity }]);

    // Automatically remove the notification after 3 seconds
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 3000);
  }, []);

  // Function to close a notification manually
  const handleClose = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ sendNotification }}>
      {children}
      <Box sx={{ position: 'fixed', bottom: 24, right: 16, zIndex: 9999 }}>
        {notifications.map((notification, index) => (
          <Snackbar
            key={notification.id}
            open={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{ marginBottom: `${index * 60}px` }} // Adjust each snackbar's bottom position to stack them
          >
            <Alert
              onClose={() => handleClose(notification.id)}
              severity={notification.severity}
              sx={{ width: '100%' }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
    </NotificationContext.Provider>
  );
};